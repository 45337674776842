<template>
  <div class="left-nav d-flex justify-content-center text-start">
    <div
      v-for="nav in navItems"
      :key="nav.title"
      class="menus ms-2"
    >
      <template v-if="nav && !nav.items">
        <button
          :class="`menu-btn ${nav.display}`"
          @click="navigatePage(nav.router)"
        >
          {{ nav.title }}
        </button>
      </template>
      <template v-else>
        <button :class="`menu-btn ${nav.display}`">
          {{ nav.title }} <b-icon
            icon="chevron-down"
            scale="0.8"
          />
        </button>
        <div class="menu-list text-start">
          <a
            v-for="item in nav.items"
            :key="JSON.stringify(item)"
          >
            <div
              v-if="item.name"
              class="menu-item"
            >
              <div class="sub-menus w-100">
                <button class="sub-menu-btn w-100 d-flex justify-content-between">
                  <span>{{ item.name }}</span>
                  <span><b-icon
                    icon="chevron-right"
                    scale="0.8"
                  /></span>
                </button>
                <div class="sub-menu-list">
                  <a
                    v-for="sub in item.subs"
                    :key="sub.text"
                    @click="navigatePage(sub.router)"
                  >
                    <div class="sub-menu-item">{{ sub.text }}</div>
                  </a>
                </div>
              </div>
            </div>
            <div
              v-else
              :key="JSON.stringify(item)"
              :class="`menu-item ${item.display}`"
              @click="navigatePage(item.router)"
            >
              {{ item.text }}
            </div>
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { BIcon } from 'bootstrap-vue'
// Nav Bar content from json file
import navBarContent from '@/assets/app-content/navbar-content.json'

export default {
  name: 'LeftNavItems',
  components: {
    BIcon,
  },
  data() {
    return {
      navItems: navBarContent.navBarItems,
    }
  },
  methods: {
    navigatePage(routerLink) {
      const route = this.$route
      if (routerLink?.name !== route.name || routerLink?.path !== route.path) {
        this.$router.push(routerLink)
      }
    },
  },
}
</script>
<style lang="scss">
@import "@/@core/scss/vue/components/left-nav-items.scss";
</style>
