<template>
  <b-navbar-nav
    id="right-nav"
    class="padding-right"
  >
    <b-nav-item
      class="d-none"
      href="#"
    >
      <feather-icon
        icon="SettingsIcon"
        size="21"
      />
    </b-nav-item>
    &nbsp;
    <b-nav-item
      class="d-none"
      href="#"
    >
      <feather-icon
        class="bell-icon"
        icon="BellIcon"
        size="21"
      />
      <div class="vr" />
    </b-nav-item>
    <b-nav-item href="#">
      <div class="dropdown">
        <div class="d-flex">
          <b-badge variant="light">
            {{ userCode }}
          </b-badge>
          <feather-icon
            class="chevron-down"
            icon="ChevronDownIcon"
            size="21"
          />
        </div>
        <ul>
          <li>
            <a
              class="text-center logout-font"
              href="#"
            >
              <b-badge variant="light">{{ userCode }}</b-badge>
              {{ authUser }}</a>
          </li>
          <b-dropdown-divider />
          <li>
            <a
              class="text-center logout-font"
              href="#"
              @click="handleLogout"
            >
              <feather-icon
                icon="LogOutIcon"
                size="21"
              /> &nbsp; Sign Out</a>
          </li>
        </ul>
      </div>
    </b-nav-item>
  </b-navbar-nav>
</template>

<script>
import FeatherIcon from '@/components/feather-icon/FeatherIcon.vue'
import { ROUTES } from '@/constants'
import { LOGOUT, getAuthUser } from '@/store/modules/auth.module'
import {
  BBadge,
  BDropdownDivider,
  BNavItem, BNavbarNav,
} from 'bootstrap-vue'

export default {
  name: 'RightNavItems',
  components: {
    BNavItem,
    BNavbarNav,
    FeatherIcon,
    BDropdownDivider,
    BBadge,
  },
  computed: {
    userCode() {
      const { firstName, lastName } = getAuthUser()
      return `${firstName ? firstName[0] : ''} ${lastName ? lastName[0] : ''}`
    },
    authUser() {
      const { firstName, lastName } = getAuthUser()
      return `${firstName || ''} ${lastName || ''}`
    },
  },
  methods: {
    handleLogout() {
      this.$store.dispatch(LOGOUT)
      this.$router.push({ name: ROUTES.LOGIN.name })
    },
  },
}
</script>
<style lang="scss">
@import "@/@core/scss/vue/components/right-nav-items.scss";
</style>
