<template>
  <div>
    <k-navbar
      :left-component="leftComponent"
      :right-component="rightComponent"
      bg-color="#373D6E"
    />
    <k-sidebar
      v-if="sidebarDisplay"
      :title="sidebarTitle"
      :sub-title="sidebarSubTitle"
      :dynamic-component="sidebarComponent"
      :component-content="sidebarComponentContent"
      :is-always-open="sidebarIsAlwaysOpen"
      @dynamic-emits="sidebarFormActions"
    />
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
import LeftNavItems from '@/components/navbar/LeftNavItems.vue'
import RightNavItems from '@/components/navbar/RightNavItems.vue'
import { KNavbar, KSidebar } from '@kingpin-global/kingpin-ui'

// Content from json file
export default {
  name: 'Dashboard',
  components: {
    KNavbar,
    KSidebar,
  },
  data() {
    return {
    }
  },
  computed: {
    sidebarDisplay() {
      return this.$store.state.sidebar.display
    },
    sidebarTitle() {
      return this.$store.state.sidebar.title
    },
    sidebarComponent() {
      return this.$store.state.sidebar.component
    },
    sidebarFormActions() {
      return this.$store.state.sidebar.formActions
    },
    sidebarSubTitle() {
      return this.$store.state.sidebar.subTitle
    },
    sidebarComponentContent() {
      return this.$store.state.sidebar.componentContent
    },
    sidebarIsAlwaysOpen() {
      return this.$store.state.sidebar.isAlwaysOpen
    },
    leftComponent() {
      return LeftNavItems
    },
    rightComponent() {
      return RightNavItems
    },
  },
}
</script>
<style lang="scss">
nav {
  .navbar-brand {
    margin-left: 2em;
  }
}
</style>
